<template>
  <v-container id="dashboard" fluid tag="section">
    <v-card outlined class="mt-0">
      <v-card-title class="pb-2 pt-2 pl-2 d-flex grey lighten-4">
        <span class="text-truncate">
          Vendors
        </span>
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-row class="mt-3" dense>
          <v-col cols="12">
            <v-text-field
              append-outer-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              @change="search = $event"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-progress-linear v-show="loading" style="margin:0px" :indeterminate="loading" color="primary" />
          </v-col>
          <v-col cols="12">
            <v-data-table
              dense
              fixed-header
              locale="en"
              hide-default-footer
              :page.sync="page"
              :items-per-page.sync="itemsPerPage"
              :search="search"
              :headers="headers"
              :items="vendors"
              class="elevation-1"
              @pagination="totalResults = $event.itemsLength"
            >
              <!-- <template #item.vendor="{ item }">
                <router-link :to="'/product-list/vendor-' + item.vendor">
                  {{ item.vendor }}
                </router-link>
              </template> -->
              <template #item.products="{ item }">
                <router-link :to="'/product-list/vendor-' + item.vendor">
                  {{ item.products }}
                </router-link>
              </template>
              <template #item.vulnerabilities="{ item }">
                <router-link :to="'/vulnerabilities-search/vendor-' + item.vendor">
                  {{ item.vulnerabilities }}
                </router-link>
              </template>
            </v-data-table>
          </v-col>
          <v-col class="mt-2 flex-wrap" cols="12">
            <div style="width:200px" class="float-sm-left d-flex mb-2">
              <v-select
                v-model="itemsPerPage"
                style="width:85px"
                class="px-2 py-0 my-0 "
                :items="rowsPerPageItems"
                flat
                hide-details
              />
              <span class="mt-2  text-body-1">
                {{ (page - 1) * itemsPerPage + 1 }}-{{
                  totalResults > page * itemsPerPage ? page * itemsPerPage : totalResults
                }}
                {{ "of" }} {{ totalResults }}
              </span>
            </div>
            <v-pagination
              v-model="page"
              class="d-flex"
              :length="Math.ceil(totalResults / itemsPerPage)"
              total-visible="7"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "../../../plugins/axios"
export default {
  name: "DashboardDashboard",
  components: {},
  data() {
    return {
      vendors: [],
      loading: true,
      search: "",
      page: 1,
      itemsPerPage: 20,
      totalResults: 10,
      rowsPerPageItems: [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 30, value: 30 },
        { text: 50, value: 50 }
      ],
      headers: [
        {
          text: "Vendor Name",
          value: "vendor"
        },
        { text: "Products", value: "products", align: "right" },
        { text: "Vulnerabilities", value: "vulnerabilities", align: "right" }
      ]
    }
  },
  computed: {},
  watch: {
    page() {
      //this.getRecords()
    }
  },
  created() {
    this.getRecords()
  },
  methods: {
    getRecords() {
      this.vendors = []
      //.get("cve_list_vendors/" + ((this.page - 1) * this.itemsPerPage + 1) + "/" + this.itemsPerPage)
      axios
        .get("cve_list_vendors/0/1")
        .then(response => {
          this.vendors = response.data
          // for (const itm in response.data) {
          //   this.vendors.push({
          //     vendor: itm,
          //     products: response.data[itm].products,
          //     vulnerabilities: response.data[itm].vulnerabilities
          //   })
          // }
          //this.$log("products list ", response)
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style></style>
